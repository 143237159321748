
import { api } from "@/api/api";
import { ApiGetShortCurriculumDto } from "@/api/generated/Api";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { CurriculumRouteNames } from "@/shared/enums/RouteNames/curriculumRouteNamesEnum";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  components: { BaseTableFiltered, BaseSheet },
  name: "NewStudieplanPrerequisitesPage",
  setup(props, context) {
    const store = useStore<StoreState>();
    const router = useRouter();
    const route = useRoute();
    const isFormValid = ref(false);

    const teachingMethod = ref<string>((route.query.teachingMethod as string) || "");
    const mainCourseId = ref(+route.query.mainCourseId || 0);
    const subCourseId = ref(+route.query.subCourseId || 0);

    const existingCurriculums = ref<ApiGetShortCurriculumDto[]>([]);

    const courseAreaItems = computed(() => {
      if (mainCourseId.value !== 0 && store.state.plans.defaultOptions.courseAreas) {
        const area = store.state.plans.defaultOptions.courseAreas.find((obj: any) => obj.id === mainCourseId.value);
        return area?.children;
      }
      return [];
    });

    const headers = [
      { text: "Id", value: "id" },
      { text: "Navn", value: "name" },
      {
        text: "Medlemsorganisasjon",
        value: "orgName",
      },
      { text: "Hovedområde", value: "mainCourseName" },
      { text: "Kursområde", value: "subCourseName" },
      { text: "Undervisningsform", value: "teachingMethod" },
      {
        text: "Opprettet av",
        value: "insertedByUsername",
      },
      { text: "Handlinger", value: "actions", sortable: false },
    ];

    const updateTable = () => {
      fetchCurriculums();
      router.push({
        query: {
          teachingMethod: teachingMethod.value,
          mainCourseId: mainCourseId.value.toString(),
          subCourseId: subCourseId.value.toString(),
        },
      });
    };

    runStoreAction(store, StoreModules.Plans, StoreActions.PlansActions.FetchDefaultOptions);
    runStoreAction(store, StoreModules.SubjectRegister, StoreActions.SubjectRegisterActions.FetchSubjectRegister);

    const fetchCurriculums = async () => {
      if (subCourseId.value !== 0 && mainCourseId.value !== 0) {
        const response = await api.curriculum.getCurriculaAsync({
          MainCourseId: mainCourseId.value,
          SubCourseId: subCourseId.value,
          TeachingMethod: teachingMethod.value ?? "",
        });
        existingCurriculums.value = response.data.filter(
          (curriculum: ApiGetShortCurriculumDto) =>
            curriculum.curriculumStatusName !== "Til godkjenning" &&
            curriculum.name?.toLowerCase().includes("inaktiv") === false
        );
      }
    };

    const newCurriculum = () => {
      const isValid = (context.refs.form as Vue & { validate: () => boolean }).validate();
      if (isValid) {
        router.push({
          name: CurriculumRouteNames.CreateCurriculum,
          query: {
            teachingMethod: teachingMethod.value,
            mainCourseId: mainCourseId.value.toString(),
            subCourseId: subCourseId.value.toString(),
          },
        });
      }
    };

    onMounted(fetchCurriculums);

    return {
      mainCourseId,
      subCourseId,
      teachingMethod,
      courseAreaItems,
      validateNotEmpty,
      headers,
      newCurriculum,
      updateTable,
      existingCurriculums,
      isFormValid,
      defaultTeachingMethods: computed(() => store.state.plans.defaultOptions.teachingMethods),
      defaultCourseAreas: computed(() => store.state.plans.defaultOptions.courseAreas),
    };
  },
});
