var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('base-sheet', {
    staticClass: "mb-4 mt-3",
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "px-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Hovedområde *",
      "items": _vm.defaultCourseAreas,
      "item-value": "id",
      "item-text": function itemText(item) {
        return "".concat(item.code, " - ").concat(item.description);
      },
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.updateTable
    },
    model: {
      value: _vm.mainCourseId,
      callback: function callback($$v) {
        _vm.mainCourseId = $$v;
      },
      expression: "mainCourseId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kursområde *",
      "no-data-text": "Velg hovedområde",
      "items": _vm.courseAreaItems,
      "item-text": function itemText(item) {
        return "".concat(item.code, " - ").concat(item.description);
      },
      "item-value": "id",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.updateTable
    },
    model: {
      value: _vm.subCourseId,
      callback: function callback($$v) {
        _vm.subCourseId = $$v;
      },
      expression: "subCourseId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Undervisningsform *",
      "items": _vm.defaultTeachingMethods,
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.updateTable
    },
    model: {
      value: _vm.teachingMethod,
      callback: function callback($$v) {
        _vm.teachingMethod = $$v;
      },
      expression: "teachingMethod"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('v-btn', {
    staticClass: "my-3",
    attrs: {
      "color": "primary",
      "block": "",
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.newCurriculum
    }
  }, [_vm._v("Opprett ny studieplan")])], 1)], 1)], 1)], 1), _vm.existingCurriculums.length === 0 ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Det eksisterer ingen studieplaner med valgte kriteria. ")]) : _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.existingCurriculums
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }])
        }, [_c('span', [_vm._v("Vis detaljer")])])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }